.accordion {
    background-color:white;
    border: 2px solid var(--bluegray);
    border-radius: var(--border-radius);
    margin: 1rem 0;
}
.accordion-title {
    padding: 0 var(--small-gap);
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
}
.accordion-right {
    display: flex;
    align-content: center;
    gap: var(--small-gap);
}
.accordion-content.close{
    display: none;
}
.accordion-content.open{
    border-top: 2px solid rgba(214, 221, 223, 0.5);
} 
.accordion-content {
    padding: 0 var(--small-gap);
}
.accordion .status {
    border-radius: 50%;
    height: 10px;
    width: 10px;
}
.accordion .status.red {
    background-color: var(--red);
}
.accordion .status.green {
    background-color: var(--green);
}
.accordion-input{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:  1rem 0;
    border-bottom: 1px solid var(--bluegray);
}
.accordion-input input[type=number]{
    width: 3rem;
    height: 1rem;
    padding:  0.5rem 0.2rem 0.5rem 1rem;
    border: 1px solid var(--bluegray);
    border-radius: 0.5rem;
}
.accordion .input-row-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
