* {
  --default-gap: 3rem;
  --small-gap: 1rem;
  --border-radius: 20px;
  font-family: Montserrat;
  --bluegray: #D6DDDF;
  --background: #F4F6FA;
  --red: #B91313; 
  --green: #19B233;
  --blue: #698BFF;
}
.App {
  height: 100vh;
  background-color: var(--bluegray);
  overflow: hidden;
}
header {
  height: 10%;
}
main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--default-gap);
  padding: var(--default-gap);
  height: calc(85% - (2 * var(--default-gap))) ;
  overflow: hidden;
  margin: auto;
  max-width: 1400px;
  padding-top:200px;
  background-image: url(/src/assets/background_image.svg);
  background-position-y: 20px;
  background-repeat: no-repeat;
  background-size: 220px;
  background-position-x: center;

}
h1 {
  padding: 1rem;
  margin: 0;
}
main form {
  padding: var(--small-gap);
  overflow: scroll;
  scrollbar-color : var(--bluegray) transparent;
  scrollbar-width: thin;
  background-color: var(--background);
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 25px 50px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; 
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: var(--small-gap);
}

form input[type=text],form input[type=number]{
  border-radius: 15px;
  height: 22px;
  padding: 0.5rem;
  border: 2px solid var(--bluegray);
}
form label{
  color: black;
  font-weight: 600;
  font-size: small;
  padding-bottom: 3px;
  
}
.inputgroup{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--small-gap);
}
.input-group {
  border-radius: 20px;
  padding: var(--small-gap);
  position: relative;
}
.input-group.red{
  border: 2px solid var(--red);
}
.input-group.green{
  border: 2px solid var(--green);;
}
.inputcell{
  display: flex;
  flex-direction: column;
}
.stepheading h2{
  color: rgb(167, 165, 165);
  font-weight: 700;
  font-size: 2.5rem;
  margin: 0;
}
.stepheading h3{
  font-weight: 700;
  font-size: 1rem;
  margin-top: 0;
}
.stats {
  position: absolute;
  bottom: -10px;
  right: var(--border-radius);
  background-color: var(--background);
  padding: 0 5px;
  color: var(--red);
  font-weight: bold;
}

.stats.green {
  color: var(--green);
}
.stats.red {
  color: var(--red);
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: var(--small-gap);
  align-items: stretch;
  justify-items: center;
}
.card {
  text-align: center;
  position: relative;
  border: 2px solid var(--bluegray);
  border-radius: var(--border-radius);
  width: 100%;
  padding: var(--small-gap) 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.card:hover {
  background: var(--bluegray);
  cursor: pointer;
}
.card.active {
  border: 2px solid var(--blue)
}
.card.active:before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  background: var(--blue);
  top: 10px;
  right: 10px;
  mask-image: url(/src/assets/icons/check.svg);
}
.card .card-title {
  font-weight: 600;
  font-size: 13px;
  margin-top: 0.5rem;
}
.card img{
  max-width: 100px;
  max-height: 50px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--bluegray);
  border-radius: 10px;
 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #707070;
}

