  
  #datenschutz-container{
    position: relative;
    background-color: white;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 25px 50px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; 
    overflow: hidden;
    border-radius: var(--border-radius);
  }

  #datenschutz-document{
    overflow: scroll;
    scrollbar-color : var(--bluegray) transparent;
  scrollbar-width: thin;
    padding: var(--default-gap);
    height: 95%;
    background-color:white; 
    color: #ACB0B1;
}
#datenschutz-document.green{
  color: black;
}
  .copy-banner{
    position: absolute;
    bottom: 50%;
    right: 50%;
    width: 30%;
    transform: translate(50%, 50%);
    min-width: 200px;
    border: 2px solid #B91313;
    background-color: white;
    padding: var(--default-gap);
    color: black;
    text-align: center;
    border-radius: var(--border-radius);
    z-index: 3;
  }
  .copy-banner button{
    width: 100%;
    height: 40px;
    border: 2px solid var(--green);
    background-color: var(--green);
    color: white;
    font-weight: 700;
  }

  .copy-banner button:hover{
    background-color: white;
    color: green;
    cursor: pointer;
  }
  .copy-banner.green {
    border: 2px solid var(--green);
  }

  
